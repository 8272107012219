import {AspectRatio, Button, Center, Image, VStack} from 'native-base';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {FullScreenLayout} from '../layouts/FullScreenLayout';
import {getResource, Images} from '../utils/imagesUtils';

export const OneButtonScreen: FC = () => {
  const navigation = useNavigation<any>();
  const {t} = useTranslation();
  return (
    <FullScreenLayout withBackgroundImage>
      <VStack height="100%" alignItems="center" justifyContent="center">
        <Center w="100%" px={5}>
          <AspectRatio
            width="80%"
            ratio={{
              base: 270 / 298,
            }}>
            <Image source={getResource(Images.logo)} resizeMode="cover" />
          </AspectRatio>
          <AspectRatio
            mt="25"
            width="85%"
            ratio={{
              base: 277 / 65,
            }}>
            <Image source={getResource(Images.title)} resizeMode="cover" />
          </AspectRatio>
        </Center>
        <Button
          onPress={() => {
            navigation.navigate('Scan');
          }}
          mt={100}
          shadow={3}
          px={4}
          py={3}
          minWidth={200}
          _text={{fontSize: 'md', fontWeight: 'semibold'}}>
          {t('Escanear')}
        </Button>
      </VStack>
    </FullScreenLayout>
  );
};
