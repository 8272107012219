import 'react-native-gesture-handler';
import React, {useEffect} from 'react';
import {NativeBaseProvider} from 'native-base';
import './i18n';
import {View} from 'react-native';
import {SharedComponentsProvider, StorageService} from '@bri/shared-components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useFonts} from 'expo-font';
import {Asset} from 'expo-asset';
import Navigator from './src/navigators/Navigator';
import IPhoneOverlay from './src/components/IPhoneOverlay';
import {MAX_WINDOW_HEIGHT, WINDOW_WIDTH} from './src/utils/mixins';
import {FishChainTheme} from './src/theme/fishchain.theme';
import {Images} from './src/utils/imagesUtils';
import Config from './src/config/config';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

export default function App() {
  StorageService.setStorage(AsyncStorage);

  const [fontsLoaded] = useFonts({
    AntDesign: require(`./src/assets/fonts/AntDesign.ttf`),
    Entypo: require(`./src/assets/fonts/Entypo.ttf`),
    EvilIcons: require(`./src/assets/fonts/EvilIcons.ttf`),
    Feather: require(`./src/assets/fonts/Feather.ttf`),
    FontAwesome: require(`./src/assets/fonts/FontAwesome.ttf`),
    FontAwesome5_Brands: require(`./src/assets/fonts/FontAwesome5_Brands.ttf`),
    FontAwesome5_Regular: require(`./src/assets/fonts/FontAwesome5_Regular.ttf`),
    FontAwesome5_Solid: require(`./src/assets/fonts/FontAwesome5_Solid.ttf`),
    Fontisto: require(`./src/assets/fonts/Fontisto.ttf`),
    Foundation: require(`./src/assets/fonts/Foundation.ttf`),
    Ionicons: require(`./src/assets/fonts/Ionicons.ttf`),
    MaterialCommunityIcons: require(`./src/assets/fonts/MaterialCommunityIcons.ttf`),
    MaterialIcons: require(`./src/assets/fonts/MaterialIcons.ttf`),
    Octicons: require(`./src/assets/fonts/Octicons.ttf`),
    SimpleLineIcons: require(`./src/assets/fonts/SimpleLineIcons.ttf`),
    Zocial: require(`./src/assets/fonts/Zocial.ttf`),
    'Montserrat-Black': require('./src/assets/fonts/Montserrat/Montserrat-Black.ttf'),
    'Montserrat-BlackItalic': require('./src/assets/fonts/Montserrat/Montserrat-BlackItalic.ttf'),
    'Montserrat-Bold': require('./src/assets/fonts/Montserrat/Montserrat-Bold.ttf'),
    'Montserrat-BoldItalic': require('./src/assets/fonts/Montserrat/Montserrat-BoldItalic.ttf'),
    'Montserrat-ExtraBold': require('./src/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf'),
    'Montserrat-ExtraBoldItalic': require('./src/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf'),
    'Montserrat-ExtraLight': require('./src/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf'),
    'Montserrat-ExtraLightItalic': require('./src/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf'),
    'Montserrat-Light': require('./src/assets/fonts/Montserrat/Montserrat-Light.ttf'),
    'Montserrat-LightItalic': require('./src/assets/fonts/Montserrat/Montserrat-LightItalic.ttf'),
    'Montserrat-Medium': require('./src/assets/fonts/Montserrat/Montserrat-Medium.ttf'),
    'Montserrat-MediumItalic': require('./src/assets/fonts/Montserrat/Montserrat-MediumItalic.ttf'),
    'Montserrat-Regular': require('./src/assets/fonts/Montserrat/Montserrat-Regular.ttf'),
    'Montserrat-SemiBold': require('./src/assets/fonts/Montserrat/Montserrat-SemiBold.ttf'),
    'Montserrat-SemiBoldItalic': require('./src/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf'),
    'Montserrat-Thin': require('./src/assets/fonts/Montserrat/Montserrat-Thin.ttf'),
    'Montserrat-ThinItalic': require('./src/assets/fonts/Montserrat/Montserrat-ThinItalic.ttf'),
  });

  const loadAssets = (assets: Object) => {
    for (const val of Object.values(assets)) {
      if (typeof val === 'object') {
        loadAssets(val);
      } else {
        Asset.loadAsync(val);
      }
    }
  };

  useEffect(() => {
    loadAssets(Images);
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <NativeBaseProvider theme={FishChainTheme}>
      <SharedComponentsProvider config={Config}>
        <IPhoneOverlay />
        <View
          style={{
            width: WINDOW_WIDTH,
            maxWidth: WINDOW_WIDTH,
            height: '100%',
            maxHeight: MAX_WINDOW_HEIGHT,
            margin: 'auto',
            backgroundColor: 'white',
          }}>
          <SafeAreaProvider>
            <SafeAreaView style={{ flex: 1 }}>
              <Navigator />
            </SafeAreaView>
          </SafeAreaProvider>
        </View>
      </SharedComponentsProvider>
    </NativeBaseProvider>
  );
}
