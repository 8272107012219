import {Image} from 'react-native';

export type ImageResource = {
  uri: string;
};

const getResource = (required: number | string): ImageResource => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required);
  }
  return {uri: required};
};

const getResourceUri = (required: number | string): string => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required).uri;
  }
  return required;
};

const Images = {
  logo: require('../assets/logo.svg'),
  title: require('../assets/title.svg'),
  peix: require('../assets/peix.png'),
  background: require('../assets/background.png'),
  trout: require('../assets/trout.png'),
  '0x0b6690e399b66976488490da483d2d858e5e2435': require('../assets/restaurantes/0x0b6690e399b66976488490da483d2d858e5e2435.png'),
  restaurant: require('../assets/restaurantes/0x0b6690e399b66976488490da483d2d858e5e2435.png'),

  Abadejo: require('../assets/especies/Abadejo.jpg'),
  Acedia: require('../assets/especies/Acedia.jpg'),
  Almeja: require('../assets/especies/Almeja.jpg'),
  'Araña Negra': require('../assets/especies/Araña Negra.jpg'),
  'Atún Rojo': require('../assets/especies/Atún Rojo.jpg'),
  Berberecho: require('../assets/especies/Berberecho.jpg'),
  Besugo: require('../assets/especies/Besugo.jpg'),
  Bogavante: require('../assets/especies/Bogavante.jpg'),
  Bonito: require('../assets/especies/Bonito.jpg'),
  'Buey de Mar': require('../assets/especies/Buey de Mar.jpg'),
  Caballa: require('../assets/especies/Caballa.jpg'),
  Cabracho: require('../assets/especies/Cabracho.jpg'),
  'Calamar Arrocero': require('../assets/especies/Calamar Arrocero.jpg'),
  'Calamar Potera': require('../assets/especies/Calamar Potera.jpg'),
  Camarón: require('../assets/especies/Camarón.jfif'),
  Cangrejo: require('../assets/especies/Cangrejo.jfif'),
  'Cap-Roig': require('../assets/especies/Cap-Roig.jpg'),
  Cañadilla: require('../assets/especies/Cañadilla.jpg'),
  Centolla: require('../assets/especies/Centolla.jpg'),
  'Cigala Mallorquina': require('../assets/especies/Cigala Mallorquina.jpg'),
  Cigala: require('../assets/especies/Cigala.jpg'),
  Congrio: require('../assets/especies/Congrio.jfif'),
  Coruxo: require('../assets/especies/Coruxo.jpg'),
  Corvallo: require('../assets/especies/Corvallo.jpg'),
  'Cranca de Fonera': require('../assets/especies/Cranca de Fonera.jpg'),
  Dorada: require('../assets/especies/Dorada.jpg'),
  Ermitaño: require('../assets/especies/Ermitaño.jpg'),
  Faneca: require('../assets/especies/Faneca.jpg'),
  Gallineta: require('../assets/especies/Gallineta.jpg'),
  'Gallo San Pedro': require('../assets/especies/Gallo San Pedro.jpg'),
  'Gamba Blanca': require('../assets/especies/Gamba Blanca.jpg'),
  'Gamba Roja': require('../assets/especies/Gamba Roja.jpg'),
  Jonquillo: require('../assets/especies/Jonquillo.jpg'),
  Jurel: require('../assets/especies/Jurel.jpg'),
  Lenguado: require('../assets/especies/Lenguado.jpg'),
  Lubina: require('../assets/especies/Lubina.jpg'),
  Mejillones: require('../assets/especies/Mejillones.jpeg'),
  Maragota: require('../assets/especies/Maragota.jpg'),
  Merluza: require('../assets/especies/Merluza.jpg'),
  'Mero Gallego': require('../assets/especies/Mero Gallego.jpg'),
  Mero: require('../assets/especies/Mero.jpg'),
  Navajas: require('../assets/especies/Navajas.jpg'),
  Nécora: require('../assets/especies/Nécora.jpg'),
  'Palometa Roja': require('../assets/especies/Palometa Roja.jpg'),
  Palomina: require('../assets/especies/Palomina.jpg'),
  Percebes: require('../assets/especies/Percebes.jpg'),
  'Pez Limón': require('../assets/especies/Pez Limón.jpg'),
  'Pez Rata': require('../assets/especies/Pez Rata.jpg'),
  Pota: require('../assets/especies/Pota.jfif'),
  Pulpo: require('../assets/especies/Pulpo.jpg'),
  Rape: require('../assets/especies/Rape.jfif'),
  Rascacio: require('../assets/especies/Rascacio.jpg'),
  Raya: require('../assets/especies/Raya.jpg'),
  Rodaballo: require('../assets/especies/Rodaballo.jpg'),
  Salmonete: require('../assets/especies/Salmonete.jpg'),
  Santiaguiño: require('../assets/especies/Santiaguiño.jpg'),
  Sardina: require('../assets/especies/Sardina.jpg'),
  Sargo: require('../assets/especies/Sargo.jpg'),
  Sepia: require('../assets/especies/Sepia.jpg'),
};

export {Images, getResource, getResourceUri};
