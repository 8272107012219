import {ServiceFunctionsType, useBaseService} from '@bri/shared-components';
import {ProductServiceTypes} from '@bri/fishchain-core';

export function useProductsService() {
  const baseService = useBaseService();

  const authServiceFunctions: ServiceFunctionsType<ProductServiceTypes> = {
    get: params => baseService.get('/v1/products/get', params),
    list: params => baseService.get('/v1/products/list', params),
    transfer: params => baseService.post('/v1/products/transfer', params),
  };

  return authServiceFunctions;
}
