import {Box, Center, Column, Icon, IconButton, Image, Row, Text} from 'native-base';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AntDesign} from '@native-base/icons';
import {useNavigation} from '@react-navigation/native';
import {getResource, Images} from '../utils/imagesUtils';
import {formatWeight} from '../utils/units.utils';

type Props = {
  id?: number;
  name?: string;
  weight?: number;
  code?: string;
};

export const FishItem: FC<Props> = props => {
  const navigation = useNavigation<any>();
  const {t} = useTranslation();
  return (
    <Box rounded="lg" overflow="hidden" backgroundColor="secondary.100" mb={4}>
      <Row>
        <Image
          width={147}
          height={88}
          source={getResource(props.name && Object.keys(Images).includes(props.name) ? Images[props.name as keyof typeof Images] : Images.peix)}
          resizeMode="cover"
        />
        <Column px={4} justifyContent="center" flexGrow={1}>
          <Text bold>{props.name}</Text>
          <Text>{t('Peso: {{peso}}', {peso: formatWeight(props.weight)})}</Text>
          <Text>{t('Código: {{code}}', {code: props.code})}</Text>
        </Column>
        <Center pr={4}>
          <IconButton
            icon={<Icon as={AntDesign} name="caretright" />}
            borderRadius="full"
            color="primary"
            onPress={() => {
              navigation.navigate('Fish', {id: props.id});
            }}
          />
        </Center>
      </Row>
    </Box>
  );
};
