import React, {FC, useEffect, useState} from 'react';
import {FlatList, Image, Spinner, Text, VStack} from 'native-base';
import {useRoute} from '@react-navigation/native';
import {getWallet, isValidAddress} from '@bri/shared-components';
import {IProduct} from '@bri/fishchain-core';
import {useTranslation} from 'react-i18next';
import {FishItem} from '../components/FishItem';
import {useProductsService} from '../services/ProductsService';
import {getResource, Images} from '../utils/imagesUtils';
import {FullScreenLayout} from '../layouts/FullScreenLayout';

export const SELF_ADDRESS_PARAM = 'self';

type Props = {};

export const FishListScreen: FC<Props> = () => {
  const {params} = useRoute<any>();
  const productsService = useProductsService();

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [address, setAddress] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IProduct[]>([]);

  const [t] = useTranslation();

  const owner = params && params.owner ? params.owner : SELF_ADDRESS_PARAM;
  const init = async () => {
    if (owner !== SELF_ADDRESS_PARAM) {
      if (!isValidAddress(params.owner)) {
        setInvalidAddress(true);
      } else {
        setAddress(params.owner);
      }
    } else {
      const wallet = await getWallet();
      setAddress(wallet.address);
    }
  };

  useEffect(() => {
    init().then();
  }, []);

  useEffect(() => {
    if (address) {
      productsService
        .list({owner: address})
        .apiResponse(() => {
          setLoading(false);
        })
        .response(resp => {
          setData(resp.products);
        })
        .error(err => console.log(err));
    }
  }, [address]);

  if (loading) {
    return <Spinner size="lg" mt={3} />;
  }
  if (!address) {
    return <Text>{t('No se ha encontrado ninguna captura.')}</Text>;
  }
  if (invalidAddress) {
    return <Text>{t('Owner invalido.')}</Text>;
  }

  return (
    <FullScreenLayout>
      <VStack flex={1} flexBasis="1">
        {params && params.owner && Object.keys(Images).includes(params.owner) && (
          <Image
            marginTop={15}
            width="70%"
            height={100}
            source={getResource(params.owner && Object.keys(Images).includes(params.owner) ? Images[params.owner as keyof typeof Images] : null)}
            resizeMode="contain"
            alignSelf="center"
          />
        )}
        <FlatList
          paddingTop={30}
          paddingX={5}
          data={data}
          renderItem={({item}) => <FishItem id={item.tokenId} name={item.material} code={item.productID} weight={item.weight?.value} />}
          keyExtractor={item => item.tokenId!.toString()}
        />
      </VStack>
    </FullScreenLayout>
  );
};
