import React from 'react';
import {QrScanner} from '@bri/shared-components';
import {Button, Center, View} from 'native-base';
import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import {FullScreenLayout} from '../layouts/FullScreenLayout';

export const ScanScreen = () => {
  const navigation = useNavigation<any>();
  const [t] = useTranslation();
  return (
    <FullScreenLayout>
      <View height="100%">
        <QrScanner
          style={{height: '100%'}}
          onQrScanned={(text: string) => {
            try {
              const url = new URL(text);
              const searchParams = new URLSearchParams(url.search);
              const id = searchParams.get('id');
              navigation.navigate('Fish', {screen: 'Fish', params: {id, client: true}});
              // eslint-disable-next-line no-empty
            } catch (ignored) {}
          }}
        />
        <Center
          style={{
            position: 'absolute',
            bottom: 20,
            left: 0,
            right: 0,
          }}>
          <Button
            onPress={() => {
              navigation.navigate('Home');
            }}>
            {t('Cerrar')}
          </Button>
        </Center>
      </View>
    </FullScreenLayout>
  );
};
