import {CommonActions, DarkTheme, DefaultTheme, NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import React, {useRef} from 'react';
import {useColorMode} from 'native-base';
import {useReduxDevToolsExtension} from '@react-navigation/devtools';
import * as Linking from 'expo-linking';
import {Platform} from 'react-native';
import {CookiesBanner, StorageService} from '@bri/shared-components';
import {WINDOW_WIDTH} from '../utils/mixins';
import TabNavigator from './TabNavigator';

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';

const Navigator = () => {
  const [isReady, setIsReady] = React.useState(false);
  const [redirect, setRedirect] = React.useState<{redirect: string; params: {}}>();
  const [initialState, setInitialState] = React.useState();
  const navigatorRef = useNavigationContainerRef<any>();
  const routeNameRef = useRef<string>();
  const {colorMode} = useColorMode();

  useReduxDevToolsExtension(navigatorRef);

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== 'web' && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await StorageService.getItem(PERSISTENCE_KEY);
          const state = savedStateString ? JSON.parse(savedStateString) : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    // We haven't finished checking for the token yet
    // return <SplashScreen />;
    return null;
  }

  const linking: any = {
    screens: {
      Home: {
        path: 'Home',
      },
      Fish: {
        path: '',
        screens: {
          Fish: {
            path: 'Fish',
          },
          FishList: {
            path: 'FishList',
          },
        },
      },
    },
  };

  return (
    <NavigationContainer
      ref={navigatorRef}
      theme={
        colorMode === 'dark'
          ? DarkTheme
          : {
              ...DefaultTheme,
              colors: {
                ...DefaultTheme.colors,
                background: 'white',
              },
            }
      }
      initialState={initialState}
      onReady={() => {
        routeNameRef.current = navigatorRef.getCurrentRoute()!.name;
      }}
      linking={{
        prefixes: ['##URL_PROJECTE##', Linking.createURL('')],
        config: linking,
      }}
      onUnhandledAction={action => {
        console.error('ERROR! Ruta no definida: ', action);
      }}
      onStateChange={async state => {
        Platform.OS !== 'web' && StorageService.setItem(PERSISTENCE_KEY, JSON.stringify(state));

        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigatorRef.getCurrentRoute()!.name;
        const trackScreenView = (routeName: string) => {
          // Your implementation of analytics goes here!
          console.log('Current route name: ', routeName);
        };

        if (redirect) {
          // && currentRouteName !== SCREENS.App) {
          console.debug('Trying to go... ', redirect.redirect);
          navigatorRef.dispatch(CommonActions.navigate(redirect.redirect, redirect.params));
          setRedirect(undefined);
        }

        if (previousRouteName !== currentRouteName) {
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;

          // Replace the line below to add the tracker from a mobile analytics SDK
          await trackScreenView(currentRouteName);
        }
      }}>
      <TabNavigator />
      <CookiesBanner
        maxWidth={WINDOW_WIDTH}
        onAcceptPres={() => {
          navigatorRef.navigate('Cookies');
        }}
      />
    </NavigationContainer>
  );
};

export default Navigator;
