import {Button, Card, HStack, Icon, Image, Link, ScrollView, Spinner, Text, View, VStack} from 'native-base';
import React, {FC, useEffect, useState} from 'react';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {IProduct} from '@bri/fishchain-core';
import {AntDesign} from '@native-base/icons';
import {useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';
import {useNavigation, useRoute} from '@react-navigation/native';
import {getWallet, TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {ScreensParams} from '../navigators/screens';
import {FullScreenLayout} from '../layouts/FullScreenLayout';
import {getResource, Images} from '../utils/imagesUtils';
import {useProductsService} from '../services/ProductsService';
import {formatWeight} from '../utils/units.utils';
import {SELF_ADDRESS_PARAM} from './FishListScreen';

type Props = {};

export const FishScreen: FC<Props> = props => {
  const productsService = useProductsService();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [obtaining, setObtaining] = useState(false);
  const [fish, setFish] = useState<IProduct>();
  const [owner, setOwner] = useState<string>();
  const [txHash, setTxHash] = useState<string>();
  const sharedToast = useSharedToast();
  const {params} = useRoute<any>();

  const navigation = useNavigation<any>();
  const fishId = params && Number(params.id);
  const claimable = params && params.claimable;
  const canObtain = params && params.client !== undefined;

  useEffect(() => {
    if (fishId) {
      productsService
        .get({tokenID: fishId})
        .apiResponse(() => {
          setLoading(false);
        })
        .response(async resp => {
          setFish(resp.product);
          const wallet = await getWallet();
          if (wallet.address.toLowerCase() === resp.owner.toLowerCase()) {
            setOwner(SELF_ADDRESS_PARAM);
          } else {
            setOwner(resp.owner);
          }
          setTxHash(resp.txHash);
        })
        .error(err => console.log(err));
    } else {
      setLoading(false);
    }
  }, [fishId]);

  if (loading) {
    return <Spinner size="lg" mt={3} />;
  }
  if (!fishId) {
    return <Text>{t('ID del producto no encontrado.')}</Text>;
  }
  if (!fish) {
    return <Text>{t('ID del producto invalido.')}</Text>;
  }

  async function claimFish() {
    const wallet = await getWallet();
    productsService
      .transfer({tokenID: fishId, toAddress: wallet.address})
      .response(() => {
        setObtaining(false);
        setOwner(SELF_ADDRESS_PARAM);
        sharedToast({
          title: t('Felicidades!'),
          description: t('Has obtenido el producto.'),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      })
      .error(err => {
        sharedToast({
          title: t('Algo ha salido mal :('),
          description: t('Error al obtener el producto'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
      });
  }
  return (
    <FullScreenLayout>
      <ScrollView flex={1} flexBasis="1">
        <Image marginTop={15} width="70%" height={100} source={getResource(Images.restaurant)}
resizeMode="contain" alignSelf="center" />
        <View>
          <Card style={{backgroundColor: `#66AFE254`, borderRadius: 12, margin: 15, padding: 0}}>
            <Image
              style={{height: `262px`}}
              resizeMode="cover"
              source={getResource(fish.material && Object.keys(Images).includes(fish.material) ? Images[fish.material as keyof typeof Images] : Images.peix)}
            />
            <HStack p={4} space={2}>
              <VStack flex={2}>
                <HStack>
                  <Image
                    style={{height: 40, width: 40, borderRadius: 40 / 2}}
                    resizeMode="cover"
                    source={getResource(fish.material && Object.keys(Images).includes(fish.material) ? Images[fish.material as keyof typeof Images] : Images.peix)}
                  />
                  <VStack ml={4} flex={1}>
                    <Text fontWeight="semibold" fontSize="md">
                      {fish.ship?.name}
                    </Text>
                    <Text fontSize="xs" lineHeight="sm">
                      {t('Lote')}: {fish.ship?.id}
                    </Text>
                  </VStack>
                </HStack>
                <View mt={6}>
                  <HStack alignItems="center" space={1}>
                    <Text fontSize="lg" fontWeight="bold">
                      {fish.material}
                    </Text>
                    <Text fontSize="lg">{formatWeight(fish.weight?.value)}</Text>
                  </HStack>
                  <Text lineHeight="md">
                    {t('Código')}: {fish.captureCode}
                  </Text>
                  <Text lineHeight="md">
                    {t('Lonja')}: {fish.fishMarket?.name}
                  </Text>
                </View>
              </VStack>

              <VStack flex={1}>
                <QRCode bgColor="transparent" style={{height: 'auto', maxWidth: '100%', width: '100%'}} value={`${window.location.href}&client`} />
              </VStack>
            </HStack>
            <HStack space={4} p={4}>
              {fish.fishMarket?.certificate && (
                <View flex={1} flexBasis={0}>
                  <Link href={fish.fishMarket?.certificate} isExternal>
                    <Button
                      variant="outline"
                      _text={{fontWeight: 'semibold', fontSize: 'xs'}}
                      borderWidth={1}
                      borderColor="primary.500"
                      style={{width: '100%'}}
                      leftIcon={<Icon as={AntDesign} name="download" size="sm" />}>
                      {t('Certificado Lonja')}
                    </Button>
                  </Link>
                </View>
              )}
              {!canObtain && (
                <View flex={1} flexBasis={0}>
                  <Button
                    _text={{fontSize: 'xs'}}
                    onPress={() => {
                      navigation.navigate('FishList', {owner});
                    }}>
                    {owner === SELF_ADDRESS_PARAM ? t('Ver mis capturas') : t('Ver más capturas')}
                  </Button>
                </View>
              )}
            </HStack>

            {claimable && (
              <View p={4} pt={0} alignItems="center">
                <Button
                  width="50%"
                  _text={{fontSize: 'xs'}}
                  isDisabled={owner === SELF_ADDRESS_PARAM}
                  isLoading={obtaining}
                  isLoadingText={t('Obteniendo') as string}
                  onPress={() => {
                    claimFish();
                  }}>
                  {owner === SELF_ADDRESS_PARAM ? t('Obtenido') : t('Obtener')}
                </Button>
              </View>
            )}

            <View p={4} pt={0} alignItems="center">
              <Link href={`https://explorer.circulartrust.blueroominnovation.com/tx/${txHash}`} isExternal>
                <Text style={{textDecorationLine: 'underline'}}>
                  <Icon color="gb(23, 23, 23)" as={AntDesign} name="lock" size="sm" />
                  {t(' Certificado en el Blockchain')}
                </Text>
              </Link>
            </View>
          </Card>
        </View>
      </ScrollView>
    </FullScreenLayout>
  );
};
