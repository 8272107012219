import React from 'react';
import {useTheme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {createStackNavigator, StackNavigationOptions} from '@react-navigation/stack';
import {FishListScreen, SELF_ADDRESS_PARAM} from '../screens/FishListScreen';
import {FishScreen} from '../screens/FishScreen';
import {ScreensParams} from './screens';

const Stack = createStackNavigator<ScreensParams>();

const StackNavigator = () => {
  const theme = useTheme();
  const {t} = useTranslation();

  const headerStyle: StackNavigationOptions = {
    headerShown: true,
    headerStyle: {backgroundColor: theme.colors.secondary['500']},
    headerTitleAlign: 'center',
    headerTitleStyle: {color: 'white', fontFamily: 'Montserrat-Bold'},
  };

  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="Fish" component={FishScreen} options={{...headerStyle, title: t('Pasaporte de Producto') as string}} />
      <Stack.Screen
        name="FishList"
        component={FishListScreen}
        options={({route}) => {
          let title: string = t('Capturas Restaurante');
          if (!route.params || !route.params.owner || (route.params && route.params.owner === SELF_ADDRESS_PARAM)) {
            title = t('Mis Capturas');
          }
          return {...headerStyle, title};
        }}
      />
    </Stack.Navigator>
  );
};

export default StackNavigator;
