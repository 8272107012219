import {VStack} from 'native-base';
import {BackgroundImage} from '@bri/shared-components';
import React from 'react';
import {getResourceUri, Images} from '../utils/imagesUtils';

type Props = {
  withBackgroundImage?: boolean;
  children: JSX.Element;
};

export const FullScreenLayout = ({children, withBackgroundImage}: Props) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <VStack height="100%">
    {withBackgroundImage && <BackgroundImage uri={getResourceUri(Images.background)} />}
    {children}
  </VStack>
);
