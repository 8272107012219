const Configuration = {
  baseService: {
    baseUrl: 'http://localhost:8080',
  },
  oauth: {
    clientId: '4bfd9ea4-9d64-11ed-a8fc-0242ac120002',
    clientSecret: 'fj8aymwkctwu8duazucu7dhf',
  },
};

// Export configuration
export default Configuration;
