import {extendTheme} from 'native-base';

export const FishChainTheme = extendTheme({
  fontConfig: {
    Montserrat: {
      100: {
        normal: 'Montserrat-Light',
        italic: 'Montserrat-LightItalic',
      },
      200: {
        normal: 'Montserrat-Light',
        italic: 'Montserrat-LightItalic',
      },
      300: {
        normal: 'Montserrat-Regular',
      },
      400: {
        normal: 'Montserrat-Medium',
        italic: 'Montserrat-MediumItalic',
      },
      500: {
        normal: 'Montserrat-Medium',
        italic: 'Montserrat-MediumItalic',
      },
      600: {
        normal: 'Montserrat-SemiBold',
        italic: 'Montserrat-SemiBoldItalic',
      },
      700: {
        normal: 'Montserrat-Bold',
        italic: 'Montserrat-BoldItalic',
      },
      800: {
        normal: 'Montserrat-ExtraBold',
        italic: 'Montserrat-ExtraBoldItalic',
      },
    },
  },
  colors: {
    primary: {
      50: '#edfaff',
      100: '#baefff',
      200: '#60d9f6',
      300: '#32a7c4',
      400: '#28849b',
      500: '#1d6172',
      600: '#18515F',
      700: '#00404F',
      800: '#003546',
      900: '#00242E',
    },
    secondary: {
      50: '#E7F4FD',
      100: '#cde5f6',
      200: '#9ACDF5',
      300: '#73B8F1',
      400: '#4CA3ED',
      500: '#66AFE2',
      600: '#4794BC',
      700: '#347896',
      800: '#215D71',
      900: '#0F424B',
    },
  },
  components: {
    Button: {
      baseStyle: {
        rounded: '3xl',
      },
    },
  },
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
    mono: 'Montserrat',
  },
  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: 'light',
  },
});
