import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {ScanScreen} from '../screens/ScanScreen';
import {OneButtonScreen} from '../screens/OneButtonScreen';
import CookiesConsentScreen from '../screens/CookiesConsentScreen';
import {ScreensParams} from './screens';
import StackNavigator from './StackNavigator';

const Tab = createBottomTabNavigator<ScreensParams>();

const TabNavigator = () => (
  <Tab.Navigator screenOptions={{headerShown: false, unmountOnBlur: true}} tabBar={() => null} initialRouteName="Home">
    <Tab.Screen name="Home" component={OneButtonScreen} />
    <Tab.Screen name="Scan" component={ScanScreen} />
    <Tab.Screen name="Fish" component={StackNavigator} />
    <Tab.Screen name="Cookies" component={CookiesConsentScreen} />
  </Tab.Navigator>
);

export default TabNavigator;
