import React from 'react';
import {CookiesConsent} from '@bri/shared-components';
import * as Update from 'expo-updates';
import {useNavigation} from '@react-navigation/native';
import CommonLayout from '../layouts/CommonLayout';

const CookiesConsentScreen = () => {
  const navigation = useNavigation();
  return (
    <CommonLayout>
      <CookiesConsent
        closable
        onClose={navigation.goBack}
        onRemoveAllCookies={async () => {
          await Update.reloadAsync();
        }}
      />
    </CommonLayout>
  );
};

export default CookiesConsentScreen;
